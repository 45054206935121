import { useState, useCallback, useRef, useEffect } from "react";

import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";

import RadialWaveform from "./RadialWaveform";

import "./Player.css";

const TRACK_PROGRESS_INTERVAL_MSEC = 200;
const WAVEFORM_BARS = 128;

// const DEFAULTS = {
//   fftSize: 2048,
// };

const Player = ({ playing, setPlaying }) => {
  const [expanded] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);
  const [audioData, setAudioData] = useState([]);
  // const [audioBuffer, setAudioBuffer] = useState();

  const audioRef = useRef();
  const intervalRef = useRef();
  const audioContextRef = useRef();
  // const audioSourceRef = useRef();
  // const audioAnalyserRef = useRef();
  // const timeDomainDataRef = useRef(new Uint8Array(DEFAULTS.fftSize / 2));

  const startTrackProgressTimer = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (audioRef.current && !audioRef.current.ended) {
        setTrackProgress(
          audioRef.current.currentTime / audioRef.current.duration
        );
      }
      if (audioRef.current && audioRef.current.ended) {
        setPlaying(false);
      }
    }, [TRACK_PROGRESS_INTERVAL_MSEC]);
  }, [setPlaying]);

  const reduceBuffer = (audioBuffer) => {
    const channelData = audioBuffer.getChannelData(0);
    let audioData = [];
    let blockSize = Math.floor(channelData.length / WAVEFORM_BARS);
    for (let i = 0; i < WAVEFORM_BARS; i++) {
      let blockStart = blockSize * i;
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum += Math.abs(channelData[blockStart + j]);
      }
      audioData.push(sum / blockSize);
    }
    setAudioData(audioData);
  };

  const initAudio = useCallback(() => {
    setInitialized(true);
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();

    fetch("audio/resilience-recharged.mp3")
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        audioContextRef.current.decodeAudioData(arrayBuffer)
      )
      .then((audioBuffer) => reduceBuffer(audioBuffer));

    audioRef.current = new Audio("audio/resilience-recharged.mp3");
    // audioRef.current.play();

    // audioSourceRef.current = audioContextRef.current.createBufferSource();
    // // set the buffer in the AudioBufferSourceNode
    // audioSourceRef.current.buffer = audioBuffer;
    // // connect the AudioBufferSourceNode to the
    // // destination so we can hear the sound
    // audioSourceRef.current.connect(audioContextRef.current.destination);
    // // start the source playing
    // audioSourceRef.current.start();

    // audioAnalyserRef.current = audioContextRef.current.createAnalyser();
    // audioAnalyserRef.current.fftSize = DEFAULTS.fftSize;
    // audioAnalyserRef.current.getByteTimeDomainData(timeDomainDataRef.current);
  }, []);

  useEffect(() => {
    if (!initialized) {
      initAudio();
    } else {
      if (playing) {
        audioRef.current.play();
        startTrackProgressTimer();
      } else {
        audioRef.current.pause();
      }
      return () => audioRef.current.pause();
    }
  }, [playing, initialized, initAudio, startTrackProgressTimer]);

  return (
    <div
      className={expanded ? "player expanded" : "player"}
      onClick={() => setPlaying(!playing)}
    >
      {initialized && playing ? (
        <div>
          <PauseRoundedIcon fontSize="large" />
        </div>
      ) : initialized && !playing ? (
        <div>
          <PlayArrowRoundedIcon fontSize="large" />
        </div>
      ) : (
        <></>
      )}
      <svg className="progress-icon" viewBox="0 0 100 100">
        <RadialWaveform r={40} audioData={audioData} progress={trackProgress} />
      </svg>
    </div>
  );
};

export default Player;
