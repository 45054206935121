import "./Footer.css";

// const kofi = `<script>
// console.log("hello");
//   kofiWidgetOverlay.draw('pixelbakery', {
//     'type': 'floating-chat',
//     'floating-chat.donateButton.text': 'Tip Us',
//     'floating-chat.donateButton.background-color': '#ff5f5f',
//     'floating-chat.donateButton.text-color': '#fff'
//   });
// </script>`;

const Footer = () => (
  <div className="footer-wrapper">
    <div className="footer">
      <a href="https://www.twitter.com/jessefischer">
        <img alt="Twitter" src="images/logos/twitter.png" />
      </a>
      <a href="https://www.instagram.com/jessefischermusic/">
        <img alt="Instagram" src="images/logos/instagram.png" />
      </a>
      <a href="https://www.facebook.com/jessefischermusic/">
        <img alt="Facebook" src="images/logos/facebook.png" />
      </a>
      <a href="https://open.spotify.com/track/0Lq8mHliGXB6IBwcpQMVWb">
        <img alt="Spotify" src="images/logos/spotify.png" />
      </a>
      <a href="https://jessefischer.bandcamp.com/track/resilience-recharged">
        <img alt="Bandcamp" src="images/logos/bandcamp.png" />
      </a>
    </div>
    <div className="kofi">
      <a href="https://ko-fi.com/C0C41QIGF" target="_blank" rel="noreferrer">
        <img
          height={36}
          style={{ border: 0, height: 36 }}
          src="https://cdn.ko-fi.com/cdn/kofi1.png?v=3"
          border="0"
          alt="Buy Me a Coffee at ko-fi.com"
        />
      </a>
    </div>
  </div>
);

export default Footer;
