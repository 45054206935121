import { useEffect, useState } from "react";

import "./App.css";

import { motion, useViewportScroll, useTransform } from "framer-motion";

import Player from "./components/Player";
import Footer from "./components/Footer";

function App() {
  const [mainState, setMainState] = useState("initial");
  const [playing, setPlaying] = useState(false);

  const { scrollY } = useViewportScroll();

  const backgroundTextureY = useTransform(scrollY, (y) => y * -0.125);

  useEffect(() => {
    if (mainState === "final") setPlaying(true);
  }, [mainState]);

  return (
    <div className={"App " + mainState}>
      <div
        className="splash-wrapper"
        onTransitionEnd={(e) =>
          e.propertyName === "opacity" && setMainState("final")
        }
      >
        <img
          height="250"
          width="250"
          src="images/cover-512w.png"
          alt="Resilience (Recharged) album cover"
          onClick={() => setMainState("transition")}
        />
      </div>
      <div className="content-wrapper">
        <Player playing={playing} setPlaying={setPlaying} />
        <motion.div className="orange-glow"></motion.div>
        <motion.div
          className="background-texture"
          style={{ y: backgroundTextureY }}
        ></motion.div>
        <div className="image-container">
          <img alt="Jesse at keyboards" src="images/jesse-540w.png" />
        </div>
        <div className="credits-container">
          <div className="about">
            <h1>resilience (recharged)</h1>
            <p>
              “Resilience (Recharged)” is a complete re-imagining of the title
              track from my last album <em>Resilience</em> (from 2020).
              Deconstructing the stems from the original recording session, I
              traded acoustic piano and horns for analog synths and vintage 90’s
              drum machines, for a chilled-out, stripped-down and slightly woozy
              electronic approach, premiered by Gilles Peterson on his
              Worldwide.FM show.
            </p>
          </div>

          <div className="credits">
            <h1>credits</h1>

            <p>
              <span className="semibold">Jesse Fischer:</span> piano, rhodes,
              Hammond B-3, Prophet Rev2, Moog Little Phatty, Juno 106, Moog
              DFAM, hi-hats, percussion, drum programming.
            </p>

            <p>
              Composed, arranged, produced and mixed by Jesse Fischer.
              <br />
              Mastered by <a href="http://alexdeturk.com/">Alex DeTurk</a>.
              <br />
              Illustration by{" "}
              <a href="http://www.margalitcutler.com/">Margalit Cutler</a>.
              <br />
              Animation by{" "}
              <a href="https://www.instagram.com/slowya.roll/">Slowya Roll</a>.
              <br />
              Site design and development by Jesse Fischer.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
