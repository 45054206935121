const RadialWaveform = ({ audioData, r, n = 36, progress = 0.2 }) => {
  const origin = { x: 50, y: 50 };
  return (
    <g stroke="#16111a" strokeWidth="2">
      {audioData.map((amplitude, index) => (
        <line
          opacity={index / audioData.length < progress ? 0.35 : 0.1}
          key={index}
          x1={
            origin.x +
            (r / 2) * Math.sin((index / audioData.length) * Math.PI * 2)
          }
          y1={
            origin.y -
            (r / 2) * Math.cos((index / audioData.length) * Math.PI * 2)
          }
          x2={
            origin.x +
            r *
              2.25 *
              (amplitude + 0.25) *
              Math.sin((index / audioData.length) * Math.PI * 2)
          }
          y2={
            origin.y -
            r *
              2.25 *
              (amplitude + 0.25) *
              Math.cos((index / audioData.length) * Math.PI * 2)
          }
        />
      ))}
    </g>
  );
};

export default RadialWaveform;
